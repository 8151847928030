import type { Stripe } from "stripe";
import ky from "ky";

import type {
  InsertOrganisation,
  Invitation,
  Organisation,
  OrganisationPlan,
  User,
  UserRole,
} from "@flow/db/schemas";

import { API_URL } from "../constants";
import { createHeaders, createUrl } from "../lib/fetch";

interface Auth {
  accessToken: string | null;
  orgId: string;
}

export interface TGetOrganisation {
  id: string;
  auth: Auth;
}

export const getOrganisation = async ({ id, auth }: TGetOrganisation) => {
  const headers = createHeaders(auth.accessToken);
  const url = createUrl(`${API_URL}/organisations/${id}`, {});

  return ky<Organisation>(url, { headers: headers }).json();
};

export interface TGetOrganisationMembers {
  id: string;
  auth: Auth;
}

export const getOrganisationMembers = async ({
  id,
  auth,
}: TGetOrganisationMembers) => {
  const headers = createHeaders(auth.accessToken);
  const url = createUrl(`${API_URL}/organisations/${id}/members`, {});

  return ky<{
    users: (User & { role: UserRole })[];
    invitations: Omit<Invitation, "token">[];
  }>(url, { headers }).json();
};

export interface TGetOrganisationOnboarding {
  id: string;
  auth: Auth;
}

export const getOrganisationOnboarding = async ({
  id,
  auth,
}: TGetOrganisationOnboarding) => {
  const headers = createHeaders(auth.accessToken);
  const url = createUrl(`${API_URL}/organisations/${id}/onboarding`, {});

  return ky<{
    hasMetric: boolean;
    hasCredentials: boolean;
    hasOauth: boolean;
  }>(url, { headers }).json();
};

export interface TGetOrganisations {
  auth: Omit<Auth, "orgId">;
}

export const getOrganisations = async ({ auth }: TGetOrganisations) => {
  const headers = createHeaders(auth.accessToken);
  const url = createUrl(`${API_URL}/organisations`, {});

  return ky<Organisation[]>(url, { headers }).json();
};

export interface TCreateOrganisation {
  data: InsertOrganisation;
  auth: Omit<Auth, "orgId">;
}

export const createOrganisation = async ({
  data,
  auth,
}: TCreateOrganisation) => {
  const headers = createHeaders(auth.accessToken);
  const url = createUrl(`${API_URL}/organisations`, {});

  return ky.post<Organisation>(url, { headers, json: data }).json();
};

export interface TUpdateOrganisationPlan {
  data: { plan: OrganisationPlan };
  id: string;
  auth: Auth;
}

export const updateOrganisationPlan = async ({
  data,
  id,
  auth,
}: TUpdateOrganisationPlan) => {
  const headers = createHeaders(auth.accessToken);
  const url = createUrl(`${API_URL}/organisations/${id}/changeplan`, {});

  return ky.post<Organisation>(url, { headers, json: data }).json();
};

export interface TCreateStripePortalUrl {
  data: { orgId: string };
  auth: Auth;
}

export const createStripePortalUrl = async ({
  data,
  auth,
}: TCreateStripePortalUrl) => {
  const headers = createHeaders(auth.accessToken);
  const url = createUrl(
    `${API_URL}/organisations/${auth.orgId}/stripe/portal`,
    {},
  );

  return ky<{ url: string }>(url, { headers, json: data }).json();
};

export interface TCreateStripeCheckoutSession {
  data: { plan: OrganisationPlan };
  auth: Auth;
}

export const createStripeCheckoutSession = async ({
  data,
  auth,
}: TCreateStripeCheckoutSession) => {
  const headers = createHeaders(auth.accessToken);
  const url = createUrl(
    `${API_URL}/organisations/${auth.orgId}/stripe/checkout`,
    {},
  );

  return ky
    .post<
      Stripe.Response<Stripe.Checkout.Session>
    >(url, { headers, json: data })
    .json();
};
