import type {
  Credential,
  InsertCredential,
  JobSource,
  OauthSource,
} from "@flow/db/schemas";

import { API_URL } from "../constants";
import { api, createHeaders, createUrl } from "../lib/fetch";

interface Auth {
  accessToken: string | null;
  orgId: string;
}

export interface TGetIntegrations {
  auth: Auth;
}

export const getIntegrations = async ({ auth }: TGetIntegrations) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };
  const url = createUrl(`${API_URL}/integrations`, { orgId: auth.orgId });

  const resp = await api<Credential[]>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TCreateIntegration {
  data: InsertCredential;
  auth: Auth;
}

export const createIntegration = async ({ data, auth }: TCreateIntegration) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers, body };
  const url = createUrl(`${API_URL}/integrations`, { orgId: auth.orgId });
  const resp = await api<Credential>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TTestIntegration {
  data: InsertCredential;
  auth: Auth;
}

export const testIntegration = async ({ data, auth }: TTestIntegration) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers, body };
  const url = createUrl(`${API_URL}/integrations/test`, { orgId: auth.orgId });

  const resp = await api<Credential>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TDeleteIntegration {
  integrationId: string;
  auth: Auth;
}

export const deleteIntegration = async ({
  integrationId,
  auth,
}: TDeleteIntegration) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "DELETE", headers };
  const url = createUrl(`${API_URL}/integrations/${integrationId}`, {
    orgId: auth.orgId,
  });

  const resp = await api<{ id: string }>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TGetEvents {
  id: string;
  type: "amplitude" | "mixpanel";
  auth: Auth;
}

export const getEvents = async ({ id, type, auth }: TGetEvents) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };
  const url = createUrl(`${API_URL}/integrations/events/${type}/${id}`, {
    orgId: auth.orgId,
  });

  const resp = await api<{ value: string }[]>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TGetIntegrationBets {
  type: OauthSource | undefined;
  params: { searchString?: string; projectId?: string };
  auth: Auth;
}

export const getIntegrationBets = async ({
  type,
  params,
  auth,
}: TGetIntegrationBets) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };

  const url = createUrl<{
    searchString?: string;
    projectId?: string;
    orgId: string;
  }>(`${API_URL}/integrations/bets/${type}`, {
    orgId: auth.orgId,
    ...params,
  });

  const resp = await api<JobSource[]>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};
