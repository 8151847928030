import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type {
  TGetEvents,
  TGetIntegrationBets,
  TGetIntegrations,
} from "../http/integration";
import {
  createIntegration,
  deleteIntegration,
  getEvents,
  getIntegrationBets,
  getIntegrations,
  testIntegration,
} from "../http/integration";

const KEY = "integrations";

export const useIntegrations = (data: TGetIntegrations) => {
  return useQuery({
    queryKey: [KEY],
    queryFn: () => getIntegrations(data),
    enabled: Boolean(data.auth.accessToken),
    initialData: [],
    placeholderData: [],
  });
};

export const useCreateIntegration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createIntegration,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: [KEY] });
      queryClient.setQueryData([KEY, { id: data.id }], data);
    },
  });
};

export const useTestIntegration = () => {
  return useMutation({ mutationFn: testIntegration });
};

export const useDeleteIntegration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteIntegration,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: [KEY] });
      queryClient.setQueryData([KEY, { id: data.id }], data);
    },
  });
};

export const useEvents = (data: TGetEvents) => {
  return useQuery({
    queryKey: ["events", data.type, data.id],
    queryFn: () => getEvents(data),
    enabled: Boolean(data.auth.accessToken),
  });
};

export const useWork = (data: TGetIntegrationBets) => {
  return useQuery({
    queryKey: ["bets", data.type, { ...data.params }],
    queryFn: () => getIntegrationBets(data),
    enabled: Boolean(data.auth.accessToken) && Boolean(data.type),
  });
};
