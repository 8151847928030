export const createHeaders = (token?: string | null): Headers => {
  const requestHeaders: Headers = new Headers();
  requestHeaders.set("Content-Type", "application/json");
  if (token) {
    requestHeaders.set("Authorization", `Bearer ${token}`);
  }
  return requestHeaders;
};

export const createUrl = <T extends { orgId?: string }>(
  path: string,
  params: T,
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = new URL(`${path}?${queryString}`);
  return url;
};

type apiResponseType<T> =
  | { ok: true; data: T }
  | { ok: false; message: string };

export const api = async <T>(
  url: string | URL,
  options: RequestInit,
): Promise<apiResponseType<T>> => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      const text = await response.text();
      return { ok: false, message: text };
    }
    const json = (await response.json()) as T;
    return { ok: true, data: json };
  } catch (error) {
    if (error instanceof Error) {
      return { ok: false, message: error.message };
    }
    return { ok: false, message: "Unknown error" };
  }
};
