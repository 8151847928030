import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { TDeleteBet, TGetBet, TGetBets } from "../http/bets";
import { creatBet, deleteBet, getBet, getBets, updateBet } from "../http/bets";

const KEY = "bets";

export const useBets = (data: TGetBets) => {
  return useQuery({
    queryKey: [KEY],
    queryFn: () => getBets(data),
    enabled: Boolean(data.auth.accessToken),
    initialData: [],
    placeholderData: [],
  });
};

export const useBet = (data: TGetBet) => {
  return useQuery({
    queryKey: [KEY, { id: data.betId }],
    queryFn: () => getBet(data),
    enabled: Boolean(data.auth.accessToken),
  });
};

export const useCreateBet = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: creatBet,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: [KEY] });
      queryClient.setQueryData([KEY, { id: data.id }], data);
    },
  });
};

export const useUpdateBet = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateBet,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: [KEY] });
      queryClient.setQueryData([KEY, { id: data.id }], data);
    },
  });
};

export const useDeleteBet = (): UseMutationResult<
  { id: string },
  Error,
  TDeleteBet
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteBet,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: [KEY] });
      queryClient.setQueryData([KEY, { id: data.id }], null);
    },
  });
};
