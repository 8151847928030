import type {
  Board,
  InsertMetric,
  InsertMetricInterval,
  InsertMetricKpi,
  InsertMetricSource,
  MetricEvent,
  MetricExpanded,
  Node,
} from "@flow/db/schemas";
import type { EventQuerySchema, MetricsQuerySchema } from "@flow/validators";

import { API_URL } from "../constants";
import { api, createHeaders, createUrl } from "../lib/fetch";

type MetricWithNodes = MetricExpanded & {
  nodes: (Node & {
    board: Board;
  })[];
};

interface Auth {
  accessToken: string | null;
  orgId: string;
}

export interface TGetMetrics {
  auth: Auth;
  params: MetricsQuerySchema;
}

export const getMetrics = async ({ auth, params }: TGetMetrics) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };
  const url = createUrl(`${API_URL}/metrics`, { orgId: auth.orgId, ...params });

  const resp = await api<{ data: MetricWithNodes[]; pageCount: number }>(
    url,
    opts,
  );

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TGetMetric {
  metricId: string;
  auth: Auth;
}

export const getMetric = async ({ metricId, auth }: TGetMetric) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };

  const resp = await api<MetricExpanded>(
    `${API_URL}/metrics/${metricId}?orgId=${auth.orgId}`,
    opts,
  );

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TGetMetricEvents {
  metricId: string;
  auth: Auth;
  options?: EventQuerySchema;
}

export const getMetricEvents = async ({
  metricId,
  auth,
  options,
}: TGetMetricEvents) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };

  const formBody = Object.entries(options ?? {})
    .map(([key, value]) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);
      return `${encodedKey}=${encodedValue}`;
    })
    .join("&");

  const resp = await api<{
    data: MetricEvent[];
    name: string;
    pageCount: number;
  }>(
    `${API_URL}/metrics/${metricId}/events?${formBody}&orgId=${auth.orgId}`,
    opts,
  );

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TCreateMetric {
  auth: Auth;
  data: InsertMetric;
}

export const createMetric = async ({ data, auth }: TCreateMetric) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers, body };
  const url = createUrl(`${API_URL}/metrics`, { orgId: auth.orgId });

  const resp = await api<MetricExpanded>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TUpdateMetric {
  metricId: string;
  auth: Auth;
  data: InsertMetric;
}

export const updateMetric = async ({ metricId, data, auth }: TUpdateMetric) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "PUT", headers, body };
  const url = createUrl(`${API_URL}/metrics/${metricId}`, {
    orgId: auth.orgId,
  });

  const resp = await api<MetricExpanded>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TDeleteMetric {
  metricId: string;
  auth: Auth;
}

export const deleteMetric = async ({ metricId, auth }: TDeleteMetric) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "DELETE", headers };
  const url = createUrl(`${API_URL}/metrics/${metricId}`, {
    orgId: auth.orgId,
  });

  const resp = await api<{ id: string }>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TUpdateMetricSource {
  metricId: string;
  data: InsertMetricSource;
  auth: Auth;
}

export const updateMetricSource = async ({
  metricId,
  data,
  auth,
}: TUpdateMetricSource) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "PUT", headers, body };

  const url = createUrl(`${API_URL}/metrics/${metricId}/source`, {
    orgId: auth.orgId,
  });

  const resp = await api<MetricExpanded>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TRunMetricSource {
  metricId: string;
  auth: Auth;
}

export const runMetricSource = async ({ metricId, auth }: TRunMetricSource) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers };
  const url = createUrl(`${API_URL}/metrics/${metricId}/source/run`, {
    orgId: auth.orgId,
  });

  const resp = await api<MetricExpanded[]>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TCreateMetricInterval {
  metricId: string;
  auth: Auth;
  data: InsertMetricInterval;
}

export const createMetricInterval = async ({
  metricId,
  data,
  auth,
}: TCreateMetricInterval) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers, body };
  const url = createUrl(`${API_URL}/metrics/${metricId}/intervals`, {
    orgId: auth.orgId,
  });

  const resp = await api<MetricExpanded>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TUpdateMetricIntervals {
  metricId: string;
  auth: Auth;
  data: InsertMetricInterval[];
}

export const updateMetricIntervals = async ({
  metricId,
  data,
  auth,
}: TUpdateMetricIntervals) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "PUT", headers, body };
  const url = createUrl(`${API_URL}/metrics/${metricId}/intervals`, {
    orgId: auth.orgId,
  });

  const resp = await api<MetricExpanded>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TUpdateMetricInterval {
  metricId: string;
  intervalId: string;
  auth: Auth;
  data: InsertMetricInterval;
}

export const updateMetricInterval = async ({
  metricId,
  intervalId,
  data,
  auth,
}: TUpdateMetricInterval) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "PUT", headers, body };
  const url = createUrl(
    `${API_URL}/metrics/${metricId}/intervals/${intervalId}`,
    { orgId: auth.orgId },
  );

  const resp = await api<MetricExpanded>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TRunMetricInterval {
  metricId: string;
  auth: Auth;
}

export const runMetricInterval = async ({
  metricId,
  auth,
}: TRunMetricInterval) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers };
  const url = createUrl(`${API_URL}/metrics/${metricId}/intervals/run`, {
    orgId: auth.orgId,
  });

  const resp = await api<MetricExpanded>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TCreateMetricKPI {
  metricId: string;
  data: InsertMetricKpi;
  auth: Auth;
}

export const createMetricKPI = async ({
  metricId,
  data,
  auth,
}: TCreateMetricKPI) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers, body };
  const url = createUrl(`${API_URL}/metrics/${metricId}/kpis`, {
    orgId: auth.orgId,
  });

  const resp = await api<MetricExpanded>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TUpdateMetricKPI {
  metricId: string;
  kpiId: string;
  data: InsertMetricKpi;
  auth: Auth;
}

export const updateMetricKPI = async ({
  metricId,
  kpiId,
  data,
  auth,
}: TUpdateMetricKPI) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "PUT", headers, body };
  const url = createUrl(`${API_URL}/metrics/${metricId}/kpis/${kpiId}`, {
    orgId: auth.orgId,
  });

  const resp = await api<MetricExpanded>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TDeleteMetricKPI {
  metricId: string;
  kpiId: string;
  auth: Auth;
}

export const deleteMetricKPI = async ({
  metricId,
  kpiId,
  auth,
}: TDeleteMetricKPI) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "DELETE", headers };
  const url = createUrl(`${API_URL}/metrics/${metricId}/kpis/${kpiId}`, {
    orgId: auth.orgId,
  });

  const resp = await api<MetricExpanded>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TRunMetricKPI {
  metricId: string;
  auth: Auth;
}

export const runMetricKPI = async ({ metricId, auth }: TRunMetricKPI) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers };
  const url = createUrl(`${API_URL}/metrics/${metricId}/kpis/run`, {
    orgId: auth.orgId,
  });

  const resp = await api<MetricExpanded>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};
