import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createTemplate } from "../http/templates";

export const useCreateTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createTemplate,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["projects"] });
      queryClient.setQueryData(["projects", { id: data.id }], data);
    },
  });
};
