import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { TGetMetric, TGetMetricEvents, TGetMetrics } from "../http/metric";
import {
  createMetric,
  createMetricKPI,
  deleteMetric,
  deleteMetricKPI,
  getMetric,
  getMetricEvents,
  getMetrics,
  runMetricInterval,
  runMetricKPI,
  runMetricSource,
  updateMetric,
  updateMetricInterval,
  updateMetricIntervals,
  updateMetricKPI,
  updateMetricSource,
} from "../http/metric";

export const useMetrics = (data: TGetMetrics) => {
  return useQuery({
    queryKey: ["metrics", { ...data.params }],
    queryFn: () => getMetrics(data),
    enabled: Boolean(data.auth.accessToken),
  });
};

export const useMetric = (data: TGetMetric) => {
  return useQuery({
    queryKey: ["metrics", { id: data.metricId }],
    queryFn: () => getMetric(data),
    enabled: Boolean(data.auth.accessToken),
  });
};

export const useMetricEvents = (data: TGetMetricEvents) => {
  return useQuery({
    queryKey: ["metricEvents", { id: data.metricId, ...(data.options ?? {}) }],
    queryFn: () => getMetricEvents(data),
    enabled: Boolean(data.auth.accessToken),
  });
};

export const useCreateMetric = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createMetric,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["metrics"] });
      queryClient.setQueryData(["metrics", { id: data.id }], data);
    },
  });
};

export const useUpdateMetric = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateMetric,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["metrics"] });
      queryClient.setQueryData(["metrics", { id: data.id }], data);
    },
  });
};

export const useDeleteMetric = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteMetric,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["metrics"] });
      queryClient.setQueryData(["metrics", { id: data.id }], data);
    },
  });
};

export const useUpdateMetricSource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateMetricSource,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["metrics"] });
      queryClient.setQueryData(["metrics", { id: data.id }], data);
    },
  });
};

export const useRunMetricSource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: runMetricSource,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["metricEvents"] });
      await queryClient.invalidateQueries({ queryKey: ["metrics"] });
      for (const metric of data) {
        queryClient.setQueryData(["metrics", { id: metric.id }], metric);
      }
    },
  });
};

export const useUpdateMetricIntervals = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateMetricIntervals,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["metrics"] });
      queryClient.setQueryData(["metrics", { id: data.id }], data);
    },
  });
};

export const useUpdateMetricInterval = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateMetricInterval,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["metrics"] });
      queryClient.setQueryData(["metrics", { id: data.id }], data);
    },
  });
};

export const useRunMetricInterval = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: runMetricInterval,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["metrics"] });
      queryClient.setQueryData(["metrics", { id: data.id }], data);
    },
  });
};

export const useCreateMetricKpi = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createMetricKPI,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["metrics"] });
      queryClient.setQueryData(["metrics", { id: data.id }], data);
    },
  });
};

export const useUpdateMetricKpi = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateMetricKPI,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["metrics"] });
      queryClient.setQueryData(["metrics", { id: data.id }], data);
    },
  });
};

export const useDeleteMetricKpi = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteMetricKPI,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["metrics"] });
      queryClient.setQueryData(["metrics", { id: data.id }], data);
    },
  });
};

export const useRunMetricKpi = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: runMetricKPI,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["metrics"] });
      queryClient.setQueryData(["metrics", { id: data.id }], data);
    },
  });
};
