import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { TGetJob, TGetJobs } from "../http/jobs";
import { creatJob, getJob, getJobs, updateJob } from "../http/jobs";

export const useJobs = (data: TGetJobs) => {
  return useQuery({
    queryKey: ["jobs"],
    queryFn: () => getJobs(data),
    enabled: Boolean(data.auth.accessToken),
    initialData: [],
    placeholderData: [],
  });
};

export const useJob = (data: TGetJob) => {
  return useQuery({
    queryKey: ["jobs", { id: data.jobId }],
    queryFn: () => getJob(data),
    enabled: Boolean(data.auth.accessToken),
  });
};

export const useCreateJob = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: creatJob,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["bets"] });
      queryClient.setQueryData(["bets", { id: data.id }], data);
    },
  });
};

export const useUpdateJob = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateJob,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["jobs"] });
      queryClient.setQueryData(["jobs", { id: data.id }], data);
    },
  });
};
