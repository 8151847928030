import type { Oauth, OauthSource } from "@flow/db/schemas";

import { API_URL, AUTH_URL } from "../constants";
import { api, createHeaders, createUrl } from "../lib/fetch";

interface Auth {
  accessToken: string | null;
  orgId: string;
}

export interface TGetConnections {
  auth: Auth;
}

export const getConnections = async ({ auth }: TGetConnections) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };
  const url = createUrl(`${API_URL}/connections`, { orgId: auth.orgId });

  const resp = await api<Oauth[]>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TDeleteConnections {
  auth: Auth;
  data: {
    type: OauthSource;
  };
}

export const deleteConnection = async ({ auth, data }: TDeleteConnections) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "DELETE", headers };
  const url = createUrl(`${AUTH_URL}/${data.type}/disconnect`, {
    orgId: auth.orgId,
  });

  const resp = await api<{ status: string }>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};
