import type { User } from "@flow/db";
import type { InsertUserWaitingList, UserWaitingList } from "@flow/db/schemas";

import { API_URL, BACKEND_URL } from "../constants";
import { api, createHeaders, createUrl } from "../lib/fetch";

interface Auth {
  accessToken: string | null;
  orgId: string;
}

export interface TGetProfile {
  auth: Omit<Auth, "orgId">;
}

export const getProfile = async ({ auth }: TGetProfile) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };
  const url = createUrl(`${API_URL}/me`, {});

  const resp = await api<User>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TCreateWaitingList {
  data: InsertUserWaitingList;
}

export const createWaitingList = async ({ data }: TCreateWaitingList) => {
  const body = JSON.stringify(data);
  const headers = createHeaders();
  const opts: RequestInit = { method: "POST", headers, body };
  const url = createUrl(`${BACKEND_URL}/public/waiting-list`, {});

  const resp = await api<UserWaitingList>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};
