import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type {
  TGetOrganisation,
  TGetOrganisationMembers,
  TGetOrganisationOnboarding,
  TGetOrganisations,
} from "../http/organisation";
import {
  createOrganisation,
  getOrganisation,
  getOrganisationMembers,
  getOrganisationOnboarding,
  getOrganisations,
} from "../http/organisation";

export const useOrganisation = (data: TGetOrganisation) => {
  return useQuery({
    queryKey: ["organisations", { id: data.id }],
    queryFn: () => getOrganisation(data),
    enabled: Boolean(data.auth.accessToken),
  });
};

export const useOrganisationMembers = (data: TGetOrganisationMembers) => {
  return useQuery({
    queryKey: ["organisations", { type: "members", org: data.auth.orgId }],
    queryFn: () => getOrganisationMembers(data),
    enabled: Boolean(data.auth.accessToken),
  });
};

export const useOrganisationOnboarding = (data: TGetOrganisationOnboarding) => {
  return useQuery({
    queryKey: ["onboarding", { id: data.id }],
    queryFn: () => getOrganisationOnboarding(data),
    enabled: Boolean(data.auth.accessToken),
  });
};

export const useOrganisations = (data: TGetOrganisations) => {
  return useQuery({
    queryKey: ["organisations"],
    queryFn: () => getOrganisations(data),
    enabled: Boolean(data.auth.accessToken),
  });
};

export const useCreateOrganisation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createOrganisation,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["organisations"] });
      queryClient.setQueryData(["organisations", { id: data.id }], data);
    },
  });
};
