import { useQuery } from "@tanstack/react-query";

import type { TGetProfile } from "../http/profile";
import { getProfile } from "../http/profile";

export const useProfile = (data: TGetProfile) => {
  return useQuery({
    queryKey: ["profile"],
    queryFn: () => getProfile(data),
    enabled: Boolean(data.auth.accessToken),
  });
};
