import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { TDeleteConnections, TGetConnections } from "../http/connection";
import { deleteConnection, getConnections } from "../http/connection";

const KEY = "connections";

export const useConnections = (data: TGetConnections) => {
  return useQuery({
    queryKey: [KEY],
    queryFn: () => getConnections(data),
    enabled: Boolean(data.auth.accessToken),
  });
};

export const useDeleteConnection = (): UseMutationResult<
  object,
  Error,
  TDeleteConnections
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteConnection,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [KEY] });
    },
  });
};
