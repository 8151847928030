import type {
  BoardWithNodeAndEdge,
  Edge,
  InsertBoard,
  InsertEdge,
  InsertNode,
  Node,
  UpdateNode,
} from "@flow/db/schemas";

import { API_URL } from "../constants";
import { api, createHeaders, createUrl } from "../lib/fetch";

interface Auth {
  accessToken: string | null;
  orgId: string;
}

export interface TGetProjects {
  auth: Auth;
}

export const getProjects = async ({ auth }: TGetProjects) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };
  const url = createUrl(`${API_URL}/boards`, { orgId: auth.orgId });

  const resp = await api<BoardWithNodeAndEdge[]>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TGetProject {
  projectId: string;
  auth: Auth;
}

export const getProject = async ({ projectId, auth }: TGetProject) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };
  const url = createUrl(`${API_URL}/boards/${projectId}`, {
    orgId: auth.orgId,
  });

  const resp = await api<BoardWithNodeAndEdge>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TCreateProject {
  data: InsertBoard;
  auth: Auth;
}

export const createProject = async ({ data, auth }: TCreateProject) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers, body };
  const url = createUrl(`${API_URL}/boards`, { orgId: auth.orgId });

  const resp = await api<BoardWithNodeAndEdge>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TUpdateProject {
  data: InsertBoard;
  projectId: string;
  auth: Auth;
  options?: {
    refresh: boolean;
  };
}

export const updateProject = async ({
  data,
  projectId,
  auth,
}: TUpdateProject) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "PATCH", headers, body };
  const url = createUrl(`${API_URL}/boards/${projectId}`, {
    orgId: auth.orgId,
  });

  const resp = await api<BoardWithNodeAndEdge>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TUpdateProjectSettings {
  data: Pick<InsertBoard, "slackChannel">;
  projectId: string;
  auth: Auth;
  options?: {
    refresh: boolean;
  };
}

export const updateProjectSettings = async ({
  data,
  projectId,
  auth,
}: TUpdateProjectSettings) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "PATCH", headers, body };
  const url = createUrl(`${API_URL}/boards/${projectId}/slack`, {
    orgId: auth.orgId,
  });

  const resp = await api<BoardWithNodeAndEdge>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TDeleteProject {
  projectId: string;
  auth: Auth;
}

export const deleteProject = async ({ projectId, auth }: TDeleteProject) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "DELETE", headers };
  const url = createUrl(`${API_URL}/boards/${projectId}`, {
    orgId: auth.orgId,
  });

  const resp = await api<{ id: string }>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TCreateNode {
  data: InsertNode;
  projectId: string;
  auth: Auth;
}

export const createNode = async ({ data, projectId, auth }: TCreateNode) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers, body };
  const url = createUrl(`${API_URL}/boards/${projectId}/nodes`, {
    orgId: auth.orgId,
  });

  const resp = await api<Node>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TUpdateNode {
  data: UpdateNode;
  projectId: string;
  nodeId: string;
  auth: Auth;
  options?: {
    refresh: boolean;
  };
}

export const updateNode = async ({
  data,
  projectId,
  nodeId,
  auth,
}: TUpdateNode) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "PATCH", headers, body };
  const url = createUrl(`${API_URL}/boards/${projectId}/nodes/${nodeId}`, {
    orgId: auth.orgId,
  });

  const resp = await api<Node>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TDeleteNode {
  projectId: string;
  nodeId: string;
  auth: Auth;
}

export const deleteNode = async ({ projectId, nodeId, auth }: TDeleteNode) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "DELETE", headers };
  const url = createUrl(`${API_URL}/boards/${projectId}/nodes/${nodeId}`, {
    orgId: auth.orgId,
  });

  const resp = await api<{ id: string }>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TCreateEdge {
  data: InsertEdge;
  projectId: string;
  auth: Auth;
}

export const createEdge = async ({ data, projectId, auth }: TCreateEdge) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers, body };
  const url = createUrl(`${API_URL}/boards/${projectId}/edges`, {
    orgId: auth.orgId,
  });

  const resp = await api<Edge>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TUpdateEdge {
  data: InsertEdge;
  projectId: string;
  edgeId: string;
  auth: Auth;
}

export const updateEdge = async ({
  data,
  projectId,
  edgeId,
  auth,
}: TUpdateEdge) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "PATCH", headers, body };
  const url = createUrl(`${API_URL}/boards/${projectId}/edges/${edgeId}`, {
    orgId: auth.orgId,
  });

  const resp = await api<Edge>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TDeleteEdge {
  projectId: string;
  edgeId: string;
  auth: Auth;
}

export const deleteEdge = async ({ projectId, edgeId, auth }: TDeleteEdge) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "DELETE", headers };
  const url = createUrl(`${API_URL}/boards/${projectId}/edges/${edgeId}`, {
    orgId: auth.orgId,
  });

  const resp = await api<{ id: string }>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TRunProject {
  projectId: string;
  auth: Auth;
}

export const runProject = async ({ projectId, auth }: TRunProject) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers };
  const url = createUrl(`${API_URL}/boards/${projectId}/run`, {
    orgId: auth.orgId,
  });

  const resp = await api<BoardWithNodeAndEdge>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TSendSlackMessage {
  projectId: string;
  auth: Auth;
}

export const sendSlackMessage = async ({
  projectId,
  auth,
}: TSendSlackMessage) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers };
  const url = createUrl(`${API_URL}/boards/${projectId}/slack`, {
    orgId: auth.orgId,
  });

  const resp = await api<void>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return undefined;
};

export interface TGetSlackChannels {
  projectId: string;
  auth: Auth;
}

export const getSlackChannels = async ({
  projectId,
  auth,
}: TGetSlackChannels) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };
  const url = createUrl(`${API_URL}/boards/${projectId}/slack/channels`, {
    orgId: auth.orgId,
  });

  const resp = await api<{ name: string; id: string }[]>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};
