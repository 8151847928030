import type { Bet, InsertBet } from "@flow/db/schemas";

import { API_URL } from "../constants";
import { api, createHeaders, createUrl } from "../lib/fetch";

interface Auth {
  accessToken: string | null;
  orgId: string;
}

export interface TGetBets {
  auth: Auth;
}

export const getBets = async ({ auth }: TGetBets) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };
  const url = createUrl(`${API_URL}/bets`, { orgId: auth.orgId });

  const resp = await api<Bet[]>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TGetBet {
  betId: string;
  auth: Auth;
}

export const getBet = async ({ betId, auth }: TGetBet) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };
  const url = createUrl(`${API_URL}/bets/${betId}`, { orgId: auth.orgId });

  const resp = await api<Bet>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TCreateBet {
  data: InsertBet;
  auth: Auth;
}

export const creatBet = async ({ data, auth }: TCreateBet) => {
  const headers = createHeaders(auth.accessToken);
  const body = JSON.stringify(data);
  const opts = { method: "POST", headers, body };
  const url = createUrl(`${API_URL}/bets`, { orgId: auth.orgId });

  const resp = await api<Bet>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TUpdateBet {
  data: InsertBet;
  betId: string;
  auth: Auth;
}

export const updateBet = async ({ data, betId, auth }: TUpdateBet) => {
  const headers = createHeaders(auth.accessToken);
  const body = JSON.stringify(data);
  const opts: RequestInit = { method: "PUT", headers, body };
  const url = createUrl(`${API_URL}/bets/${betId}`, { orgId: auth.orgId });

  const resp = await api<Bet>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TDeleteBet {
  betId: string;
  auth: Auth;
}

export const deleteBet = async ({ betId, auth }: TDeleteBet) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "DELETE", headers };
  const url = createUrl(`${API_URL}/bets/${betId}`, { orgId: auth.orgId });

  const resp = await api<{ id: string }>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};
