import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { TGetInvitations } from "../http/invitations";
import {
  accecptInvitation,
  createInvitation,
  getInvitations,
} from "../http/invitations";

export const useInvitations = (data: TGetInvitations) => {
  return useQuery({
    queryKey: ["invitations"],
    queryFn: () => getInvitations(data),
    enabled: Boolean(data.auth.accessToken),
    initialData: [],
    placeholderData: [],
  });
};

export const useCreateInvitation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createInvitation,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["invitations"] });
      queryClient.setQueryData(["invitations", { id: data.id }], data);
    },
  });
};

export const useAcceptInvitation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: accecptInvitation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["invitations"] });
    },
  });
};
