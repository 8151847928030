import type { InsertNote, Note } from "@flow/db/schemas";

import { API_URL } from "../constants";
import { api, createHeaders, createUrl } from "../lib/fetch";

interface Auth {
  accessToken: string | null;
  orgId: string;
}

export interface TGetNote {
  noteId: string;
  auth: Auth;
}

export const getNote = async ({ noteId, auth }: TGetNote) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };
  const url = createUrl(`${API_URL}/notes/${noteId}`, { orgId: auth.orgId });

  const resp = await api<Note>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TCreateNote {
  data: InsertNote;
  auth: Auth;
}

export const creatNote = async ({ data, auth }: TCreateNote) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers, body };
  const url = createUrl(`${API_URL}/notes`, { orgId: auth.orgId });

  const resp = await api<Note>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TUpdateNote {
  noteId: string;
  data: InsertNote;
  auth: Auth;
}

export const updateNote = async ({ noteId, data, auth }: TUpdateNote) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "PUT", headers, body };
  const url = createUrl(`${API_URL}/notes/${noteId}`, { orgId: auth.orgId });

  const resp = await api<Note>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TDeleteNote {
  noteId: string;
  auth: Auth;
}

export const deleteNote = async ({ noteId, auth }: TDeleteNote) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "DELETE", headers };
  const url = createUrl(`${API_URL}/notes/${noteId}`, { orgId: auth.orgId });

  const resp = await api<{ id: string }>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};
