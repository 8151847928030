import type {
  InsertInvitation,
  Invitation,
  Organisation,
} from "@flow/db/schemas";

import { API_URL } from "../constants";
import { api, createHeaders, createUrl } from "../lib/fetch";

interface Auth {
  accessToken: string | null;
  orgId: string;
}

export interface TGetInvitations {
  auth: Omit<Auth, "orgId">;
}

export const getInvitations = async ({ auth }: TGetInvitations) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };
  const url = createUrl(`${API_URL}/invitations`, {});

  const resp = await api<(Invitation & { organisation: Organisation })[]>(
    url,
    opts,
  );

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TCreateInvitation {
  data: Omit<InsertInvitation, "token">;
  auth: Auth;
}

export const createInvitation = async ({
  data,
  auth,
}: TCreateInvitation): Promise<Invitation> => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers, body };
  const url = createUrl(`${API_URL}/invitations`, { orgId: auth.orgId });

  const resp = await api<Invitation>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TAccecptInvitation {
  data: Pick<InsertInvitation, "token">;
  auth: { accessToken: string };
}

export const accecptInvitation = async ({ data, auth }: TAccecptInvitation) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers, body };
  const url = createUrl(`${API_URL}/invitations/accept`, {});

  const resp = await api<Organisation>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }
  return resp.data;
};
