import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { BoardWithNodeAndEdge } from "@flow/db/schemas";

import type {
  TGetProject,
  TGetProjects,
  TGetSlackChannels,
} from "../http/boards";
import {
  createEdge,
  createNode,
  createProject,
  deleteEdge,
  deleteNode,
  deleteProject,
  getProject,
  getProjects,
  getSlackChannels,
  runProject,
  updateEdge,
  updateNode,
  updateProject,
  updateProjectSettings,
} from "../http/boards";

export const useBoards = (data: TGetProjects) => {
  return useQuery({
    queryKey: ["projects"],
    queryFn: () => getProjects(data),
    enabled: Boolean(data.auth.accessToken),
  });
};

export const useBoard = (data: TGetProject) => {
  return useQuery({
    queryKey: ["projects", { id: data.projectId }],
    queryFn: () => getProject(data),
    enabled: Boolean(data.auth.accessToken),
  });
};

export const useCreateProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createProject,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["projects"] });
      queryClient.setQueryData(["projects", { id: data.id }], data);
    },
  });
};

export const useUpdateProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateProject,
    onMutate: async ({ data, projectId }) => {
      await queryClient.cancelQueries({
        queryKey: ["projects", { id: projectId }],
      });

      // Snapshot the previous value
      const previousProject = queryClient.getQueryData<BoardWithNodeAndEdge>([
        "projects",
        { id: projectId },
      ]);
      // Optimistically update to the new value
      queryClient.setQueryData(
        ["projects", { id: projectId }],
        (old: BoardWithNodeAndEdge) => {
          return { ...old, name: data.name };
        },
      );

      // Return a context object with the snapshotted value
      return { previousProject };
    },
    onError: (_err, props, context) => {
      queryClient.setQueryData<BoardWithNodeAndEdge>(
        ["projects", { id: props.projectId }],
        context?.previousProject,
      );
    },
    // Always refetch after error or success:
    onSettled: async (_data, _error, props) => {
      if (props.options?.refresh) {
        await queryClient.invalidateQueries({
          queryKey: ["projects", { id: props.projectId }],
        });
      }
    },
  });
};

export const useUpdateProjectSettings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateProjectSettings,
    onMutate: async ({ data, projectId }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["projects", { id: projectId }],
      });

      // Snapshot the previous value
      const previousProject = queryClient.getQueryData<BoardWithNodeAndEdge>([
        "projects",
        { id: projectId },
      ]);
      // Optimistically update to the new value
      queryClient.setQueryData(
        ["projects", { id: projectId }],
        (old: BoardWithNodeAndEdge) => {
          return { ...old, ...data };
        },
      );

      // Return a context object with the snapshotted value
      return { previousProject };
    },
    onError: (_err, props, context) => {
      queryClient.setQueryData<BoardWithNodeAndEdge>(
        ["projects", { id: props.projectId }],
        context?.previousProject,
      );
    },
    // Always refetch after error or success:
    onSettled: async (_data, _error, props) => {
      if (props.options?.refresh) {
        await queryClient.invalidateQueries({
          queryKey: ["projects", { id: props.projectId }],
        });
      }
    },
  });
};

export const useDeleteProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteProject,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["projects"] });
      queryClient.setQueryData(["projects", { id: data.id }], data);
    },
  });
};

export const useCreateNode = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createNode,
    onSuccess: (createdNode) => {
      queryClient.setQueryData(
        ["projects", { id: projectId }],
        (oldProject: BoardWithNodeAndEdge) => ({
          ...oldProject,
          nodes: [...oldProject.nodes, createdNode],
        }),
      );
    },
  });
};

export const useUpdateNode = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateNode,
    onMutate: async ({ nodeId, data }) => {
      await queryClient.cancelQueries({
        queryKey: ["projects", { id: projectId }],
      });
      const previousProject = queryClient.getQueryData<BoardWithNodeAndEdge>([
        "projects",
        { id: projectId },
      ]);
      queryClient.setQueryData(
        ["projects", { id: projectId }],
        (old: BoardWithNodeAndEdge) => {
          const nodes = old.nodes.map((node) => {
            return node.id === nodeId ? { ...node, ...data } : node;
          }); // satisfies NodeInput[]
          return { ...old, nodes };
        },
      );
      return { previousProject };
    },
    onError: (_err, _, context) => {
      queryClient.setQueryData<BoardWithNodeAndEdge>(
        ["projects", { id: projectId }],
        context?.previousProject,
      );
    },
    // Always refetch after error or success:
    onSettled: async (_data, _error, props) => {
      if (props.options?.refresh) {
        await queryClient.invalidateQueries({
          queryKey: ["projects", { id: projectId }],
        });
      }
    },
  });
};

export const useUpdateNodePosition = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateNode,
    onMutate: async ({ nodeId, data }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["projects", { id: projectId }],
      });

      // Snapshot the previous value
      const previousProject = queryClient.getQueryData<BoardWithNodeAndEdge>([
        "projects",
        { id: projectId },
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        ["projects", { id: projectId }],
        (old: BoardWithNodeAndEdge) => {
          const nodes = old.nodes.map((node) => {
            return node.id === nodeId ? { ...node, ...data } : node;
          }); // satisfies NodeInput[]
          return { ...old, nodes };
        },
      );

      // Return a context object with the snapshotted value
      return { previousProject };
    },
    onError: (_err, _, context) => {
      queryClient.setQueryData<BoardWithNodeAndEdge>(
        ["projects", { id: projectId }],
        context?.previousProject,
      );
    },
    // Always refetch after error or success:
    onSettled: async (_data, _error, props) => {
      if (props.options?.refresh) {
        await queryClient.invalidateQueries({
          queryKey: ["projects", { id: projectId }],
        });
      }
    },
  });
};

export const useDeleteNode = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteNode,
    onMutate: async ({ nodeId }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["projects", { id: projectId }],
      });

      // Snapshot the previous value
      const previousProject = queryClient.getQueryData<BoardWithNodeAndEdge>([
        "projects",
        { id: projectId },
      ]);
      // Optimistically update to the new value
      queryClient.setQueryData(
        ["projects", { id: projectId }],
        (old: BoardWithNodeAndEdge) => {
          const nodes = old.nodes.filter((node) => {
            return node.id !== nodeId;
          }); // satisfies NodeInput[]
          return { ...old, nodes };
        },
      );

      // Return a context object with the snapshotted value
      return { previousProject };
    },
    onError: (_err, _, context) => {
      queryClient.setQueryData<BoardWithNodeAndEdge>(
        ["projects", { id: projectId }],
        context?.previousProject,
      );
    },
  });
};

export const useCreateEdge = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createEdge,
    onSuccess: (createdEdge) => {
      const oldProject = queryClient.getQueryData<BoardWithNodeAndEdge>([
        "projects",
        { id: projectId },
      ]);
      if (!oldProject) return;
      queryClient.setQueryData(["projects", { id: projectId }], {
        ...oldProject,
        edges: [...oldProject.edges, createdEdge],
      });
    },
  });
};

export const useUpdateEdge = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateEdge,
    onSuccess: (updatedEdge) => {
      const oldProject = queryClient.getQueryData<BoardWithNodeAndEdge>([
        "projects",
        { id: projectId },
      ]);
      if (!oldProject) return;
      const edges = oldProject.edges.map((edge) => {
        return edge.id === updatedEdge.id ? updatedEdge : edge;
      });
      queryClient.setQueryData(["projects", { id: projectId }], {
        ...oldProject,
        edges,
      });
    },
  });
};

export const useDeleteEdge = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteEdge,
    onSuccess: (deletedEdge) => {
      const oldProject = queryClient.getQueryData<BoardWithNodeAndEdge>([
        "projects",
        { id: projectId },
      ]);
      if (!oldProject) return;
      const edges = oldProject.edges.filter((edge) => {
        return edge.id !== deletedEdge.id;
      });
      queryClient.setQueryData(["projects", { id: projectId }], {
        ...oldProject,
        edges,
      });
    },
  });
};

export const useRunProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: runProject,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["projects"] });
      await queryClient.invalidateQueries({ queryKey: ["metrics"] });
      queryClient.setQueryData(["projects", { id: data.id }], data);
    },
  });
};

export const useSlackChannels = (data: TGetSlackChannels) => {
  return useQuery({
    queryKey: ["projects-slack", { id: data.projectId }],
    queryFn: () => getSlackChannels(data),
    enabled: Boolean(data.auth.accessToken),
  });
};
