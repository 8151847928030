import type { BoardWithNodeAndEdge } from "@flow/db/schemas";

import { API_URL } from "../constants";
import { api, createHeaders, createUrl } from "../lib/fetch";

interface Auth {
  accessToken: string | null;
  orgId: string;
}

export interface TCreateTemplate {
  data: { type: "musicService" | "eCommerce" | "northStar" };
  auth: Auth;
}

export const createTemplate = async ({ data, auth }: TCreateTemplate) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers, body };
  const url = createUrl(`${API_URL}/templates`, { orgId: auth.orgId });

  const resp = await api<BoardWithNodeAndEdge>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};
