import type { InsertJob, Job, JobWithSource } from "@flow/db/schemas";

import { API_URL } from "../constants";
import { api, createHeaders, createUrl } from "../lib/fetch";

interface Auth {
  accessToken: string | null;
  orgId: string;
}

export interface TGetJobs {
  auth: Auth;
}

export const getJobs = async ({ auth }: TGetJobs) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };
  const url = createUrl(`${API_URL}/jobs`, { orgId: auth.orgId });

  const resp = await api<Job[]>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TGetJob {
  jobId: string;
  auth: Auth;
}

export const getJob = async ({ jobId, auth }: TGetJob) => {
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "GET", headers };
  const url = createUrl(`${API_URL}/jobs/${jobId}`, { orgId: auth.orgId });

  const resp = await api<JobWithSource>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TCreateJob {
  data: InsertJob;
  auth: Auth;
}

export const creatJob = async ({ data, auth }: TCreateJob) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "POST", headers, body };
  const url = createUrl(`${API_URL}/jobs`, { orgId: auth.orgId });

  const resp = await api<JobWithSource>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};

export interface TUpdateJob {
  jobId: string;
  data: InsertJob;
  auth: Auth;
}

export const updateJob = async ({ jobId, data, auth }: TUpdateJob) => {
  const body = JSON.stringify(data);
  const headers = createHeaders(auth.accessToken);
  const opts: RequestInit = { method: "PUT", headers, body };
  const url = createUrl(`${API_URL}/jobs/${jobId}`, { orgId: auth.orgId });

  const resp = await api<JobWithSource>(url, opts);

  if (!resp.ok) {
    throw new Error(resp.message);
  }

  return resp.data;
};
