import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { TGetNote } from "../http/notes";
import { creatNote, deleteNote, getNote, updateNote } from "../http/notes";

export const useNote = (data: TGetNote) => {
  return useQuery({
    queryKey: ["notes", { id: data.noteId }],
    queryFn: () => getNote(data),
    enabled: Boolean(data.auth.accessToken),
  });
};

export const useCreateNote = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: creatNote,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["notes"] });
      queryClient.setQueryData(["notes", { id: data.id }], data);
    },
  });
};

export const useUpdateNote = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateNote,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["notes"] });
      queryClient.setQueryData(["notes", { id: data.id }], data);
    },
  });
};

export const useDeleteNote = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteNote,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["notes"] });
      queryClient.setQueryData(["notes", { id: data.id }], null);
    },
  });
};
